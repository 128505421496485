import { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import IntakeDataContext from './intake-data-context';

const useInputData = () => {
    const [data, setData] = useState({});
    // it's currently unclear if we need to manage the results at this level
    // const [results, setResults] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const { updateReturnedResults } = useContext(IntakeDataContext);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);
            try {
                // make a copy, not a reference
                const dataForAPI = JSON.parse(JSON.stringify(data));
                // locationInput is only used on the front end, not needed for the API transaction
                delete dataForAPI.locationInput;
                // the API doesn't need or accept this to create a result set
                delete dataForAPI.parentIssueTypeId;
                const result = await axios.post(`${process.env.REACT_APP_API}/results/recommendations`, dataForAPI);
                // not sure if we need to manage results data here
                // setResults(result.data);
                // update results in parent context so it can be used on results page
                // const storedResults = {results}
                updateReturnedResults(result.data);
            } catch (error) {
                setIsError(true);
            }

            setIsLoading(false);
        };
        if (Object.keys(data).length !== 0) {
            // this prevents the fetching on mount when object is empty
            fetchData();
        }
    }, [data, updateReturnedResults]);

    return {
        setData,
        // results,
        isLoading,
        isError,
    };
};

export default useInputData;
