import { createContext } from 'react';

const IntakeDataContext = createContext({
    resultsObj: [],
    inputObj: [],
    updateUserInput: () => {},
    updateReturnedResults: () => {},
});

export default IntakeDataContext;
