import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Styles from './styles';

const currentYear = new Date().getFullYear();

const Footer = ({ classes }) => (
    <footer className={classes.wrapper}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-auto text-center">
                    <Typography variant="body1" className={classes.copyright}>
                        &copy;
                        {currentYear}
                        &nbsp;Colorado Justice for All
                    </Typography>
                </div>
                {/*
                    <div className="col-12 col-md-auto text-center">
                        <Typography variant="body1" className={classes.footerLink}><a href="http://google.com">Privacy Policy</a></Typography>
                    </div>
                    <div className="col-12 col-md-auto text-center">
                        <Typography variant="body1" className={classes.footerLink}><a href="http://google.com">Terms of Service</a></Typography>
                    </div>
                */}
            </div>
        </div>
    </footer>
);

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(Footer);
