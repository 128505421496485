import Colors from '../../styles/colors';
import Breakpoints from '../../styles/breakpoints';

const styles = () => ({
    wrapper: {
        paddingBottom: 40,
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            paddingBottom: 20,
        },
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            width: '80%',
        },
    },
    barWrapper: {
        margin: '15px auto 0 auto',
        height: 8,
        width: 400,
        borderRadius: 6,
        background: Colors.glitter,
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            width: '100%',
        },
    },
    bar: {
        backgroundColor: Colors.green,
        width: '25%',
        height: '100%',
        borderRadius: 6,
        transition: 'all 0.25s ease',
    },
});
export default styles;
