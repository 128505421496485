import ReactGA from 'react-ga';

const GAUtils = {};
const debug = false;
GAUtils.initGA = () => {
    ReactGA.initialize('UA-145470435-1', {
        debug,
        titleCase: false,
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
};
export default GAUtils;


export const EnterLocation = (location) => {
    ReactGA.event({
        category: 'Intake',
        action: 'Location',
        label: location.toLowerCase(),
    });
};

export const EnterParentIssue = (issueId) => {
    ReactGA.event({
        category: 'Intake',
        action: 'Parent Issue',
        label: issueId,
    });
};

export const EnterChildIssue = (issueId) => {
    ReactGA.event({
        category: 'Intake',
        action: 'Child Issue',
        label: issueId,
    });
};

export const EnterSpecialCirc = (circ) => {
    ReactGA.event({
        category: 'Intake',
        action: 'Special Circumstance',
        label: JSON.stringify(circ),
    });
};
