import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Styles from './styles';

const ButtonCTA = ({
    classes,
    children,
    disabled,
    parentClass,
    onClick,
}) => (
    <Button
        disabled={disabled}
        className={`${classes.wrapper} ${parentClass}`}
        onClick={onClick}
    >
        {children}
    </Button>
);


ButtonCTA.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array]).isRequired,
    classes: PropTypes.object.isRequired,
    parentClass: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};
ButtonCTA.defaultProps = {
    disabled: false,
    parentClass: '',
    onClick: null,
};

export default withStyles(Styles)(ButtonCTA);
