import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import BenefitsIcon from './images/secondary/full_icon_benefits.png';
import EstatesIcon from './images/secondary/full_icon_estates_wills.png';
import HealthIcon from './images/secondary/full_icon_health.png';
import HousingIcon from './images/secondary/full_icon_housing.png';
import MoneyIcon from './images/secondary/full_icon_money.png';
import FamilyIcon from './images/secondary/full_icon_families.png';

import Styles from './styles-questions';

const QuestionSecondaryTopic = ({
    classes,
    updateValue,
    defaultValue,
    data,
    advanceStep,
    parentIssueTypeId,
}) => {
    const [secondaryTopic, setSecondaryTopic] = useState(defaultValue);

    const buttonSelect = (topic) => {
        updateValue(prevState => ({ ...prevState, issueTypeId: topic.id }));
        setSecondaryTopic(topic.id);
        advanceStep(topic.id);
    };

    const IconMap = {
        'BE-00-00-00-00': BenefitsIcon,
        'MO-00-00-00-00': MoneyIcon,
        'HO-00-00-00-00': HousingIcon,
        'ES-00-00-00-00': EstatesIcon,
        'HE-00-00-00-00': HealthIcon,
        'FA-00-00-00-00': FamilyIcon,
    };

    return (
        <React.Fragment>
            <Typography variant="h1" style={{ paddingBottom: 30 }}>
                What specific topic would you like to learn more about?
            </Typography>
            <Typography variant="body2" style={{ paddingBottom: 30 }}>
                Choose a specific topic to find resources and referrals in your area.
            </Typography>
            <div className="row">
                <div className="col-12 col-md-7">
                    <section style={{ paddingBottom: 30 }}>
                        {data.map(topic => (
                            <button
                                key={topic.id}
                                type="button"
                                onClick={() => buttonSelect(topic)}
                                style={{ width: '100%' }}
                                className={`text-left ${classes.selectionButton} ${secondaryTopic === topic.id && classes.selectionButtonSelected}`}
                            >
                                <Typography variant="h4">{topic.friendlyTitle}</Typography>
                                <Typography variant="body1" style={{ paddingTop: 5, paddingBottom: 0 }}>{topic.summary}</Typography>
                            </button>
                        ))}
                    </section>
                </div>
                <div className="col d-none d-md-flex justify-content-center align-items-start">
                    <img alt="question icon" src={IconMap[parentIssueTypeId]} className={classes.questionIcon} />
                </div>
            </div>
        </React.Fragment>
    );
};

QuestionSecondaryTopic.propTypes = {
    classes: PropTypes.object.isRequired,
    defaultValue: PropTypes.string.isRequired,
    updateValue: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    advanceStep: PropTypes.func.isRequired,
    parentIssueTypeId: PropTypes.string.isRequired,
};

export default withStyles(Styles)(QuestionSecondaryTopic);
