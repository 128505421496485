// regex from http://emailregex.com/
const validatePhone = (phone) => {
    if (
        /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phone)
    ) {
        return true;
    }
    // invalid phone
    return false;
};

export default validatePhone;
