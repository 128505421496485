import Colors from '../../styles/colors';
import Breakpoints from '../../styles/breakpoints';

const styles = theme => ({
    wrapper: {
        marginTop: 30,
        width: '100%',
        color: theme.palette.text.secondary,
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            marginTop: 20,
        },
    },
    resultHeader: {
        paddingTop: 60,
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            paddingTop: 20,
        },
    },
    resultsFooter: {
        marginTop: 100,
        backgroundColor: 'white',
    },
    backBtn: {
        lineHeight: '1.2em',
        color: Colors.royalBlue,
        fontSize: theme.typography.h4.fontSize,
        textTransform: 'capitalize',
        '& svg': {
            fill: Colors.iconBlue,
            marginRight: 10,
        },
    },
    resultBlock: {
        backgroundColor: 'white',
        boxShadow: '0 10px 20px 0 rgba(51,71,91,0.2)',
        padding: 25,
        marginBottom: '45px',
        borderRadius: 15,
    },
    resultListing: {
        padding: '0 15px',
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            padding: 0,
        },
    },

    resultName: {
        paddingBottom: '0',
    },
    resultDescription: {},
    gotoResultsButton: {
        minWidth: 10,
        height: 50,
        width: 50,
        borderRadius: '100%',
        backgroundColor: Colors.yellow,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: Colors.yellowHover,
        },
        '&:active': {
            backgroundColor: Colors.yellowActive,
        },
    },
    resultBlockIcon: {
        fontSize: 30,
    },
    breakReturn: {
        display: 'block',
        height: 1,
        border: 0,
        borderTop: `${Colors.borderGrey} 1px solid`,
        padding: 0,
        margin: '30px 15px',
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            margin: '15px 0',
        },
    },
    resultsBlockIcon: {
        width: 60,
    },
    /* build animation */
    resultSection: {
        marginTop: 0,
        top: 0,
        opacity: 1,
        transition: 'all 0.25s ease',
    },
    resultSectionEntering: {
        marginTop: 50,
        opacity: 0,
    },
    resultSectionEntered: {
        marginTop: 0,
        opacity: 1,
    },
    // drawer
    drawerCloseWrapper: {
        backgroundColor: Colors.glitter,
        textAlign: 'right',
        padding: '10px 10px 0 10px',
    },
    drawerCloseButton: {
        cursor: 'pointer',
        '&:focus': {
            border: `1px solid ${Colors.yellow}`,
            marginLeft: -1,
        },
        '& svg': {
            width: 30,
            fill: Colors.royalBlue,
        },
    },
    recommendationDrawer: {},
    recommendationDrawerPaper: {
        width: 475,
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            width: '100%',
        },
    },
    launchIcon: {
        color: 'grey',
        width: 20,
        marginLeft: 5,
        marginBottom: 3,
    },
    textLink: {
        color: Colors.royalBlue,
        fontWeight: 'bold',
        fontSize: 15,
    },
    actionButton: {
        boxShadow: '0 20px 60px 0 rgba(17,34,51,0.4)',
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            width: '100%',
        },
    },
});
export default styles;
