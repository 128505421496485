import React, {
    useEffect,
    useState,
    useContext,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import QuickExitDialog from '../../components/quick-exit';
import GetParameterByName from '../../utils/getParam';

import Styles from './styles';
import CommunityIcon from './images/community.png';
import LawIcon from './images/law.png';
import DIYIcon from './images/diy.png';

import { history } from '../../store';

import useInputData from '../../wiring/use-intake-data';
import IntakeDataContext from '../../wiring/intake-data-context';

// this is a custom hook, used to compare previous states to current
// if reused, we can externalize it from this component
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const LoadingResults = ({ classes, location }) => {
    const specialIdParam = GetParameterByName('special', location.search);

    const [loadingStep, setLoadingStep] = useState(1);
    const { setData, isError, isLoading } = useInputData();
    const { inputObj } = useContext(IntakeDataContext);
    const prevState = usePrevious({ isLoading });

    const gotoResults = () => {
        history.push('/results');
    };

    useEffect(() => {
        if (specialIdParam) {
            setData({ specialCircumstances: [parseInt(specialIdParam, 10)] });
        } else if (Object.keys(inputObj).length !== 0) {
            // parentIssueTypeId & locationInput are deleted before sending to the API
            setData(inputObj);
        } else {
            // no data, so fetching results would fail
            history.push('/404');
        }
    }, []); // we only want this to run on mount
    // this will throw warnings because we are using elements of state inside the useEffect

    useEffect(() => {
        if (isError) {
            history.push('/404');
        }
    }, [isError]);

    useEffect(() => {
        // once we start loading, start our count down
        let timeout;
        let intervalStep2;
        let intervalStep3;
        let intervalStep4;

        // if we had been loading, but are done, let's do our count down
        // we don't want to count down till done loading, so we don't beat
        // the results data to the results page
        if (prevState && prevState.isLoading === true) {
            timeout = setTimeout(() => gotoResults(), 4000);
            intervalStep2 = setTimeout(() => setLoadingStep(2), 1000);
            intervalStep3 = setTimeout(() => setLoadingStep(3), 2000);
            intervalStep4 = setTimeout(() => setLoadingStep(4), 3000);
        }
        return () => {
            clearTimeout(timeout);
            clearTimeout(intervalStep2);
            clearTimeout(intervalStep3);
            clearTimeout(intervalStep4);
        };
    }, [isLoading]);

    return (
        <React.Fragment>
            <div className={classes.wrapper}>
                <div className="pt-3">
                    <QuickExitDialog />
                </div>
                <Typography variant="body2">
                    Searching for resources near you...
                </Typography>
                {loadingStep === 1 && (
                    <React.Fragment>
                        <div className={classes.iconCircle}>
                            <img className={classes.icon} src={CommunityIcon} alt="Community Help" />
                        </div>
                        <Typography variant="h3">
                            Community Help
                        </Typography>
                    </React.Fragment>
                )}
                {loadingStep === 2 && (
                    <React.Fragment>
                        <div className={classes.iconCircle}>
                            <img className={classes.icon} src={LawIcon} alt="Legal Help" />
                        </div>
                        <Typography variant="h3">
                            Legal Help
                        </Typography>
                    </React.Fragment>
                )}
                {loadingStep >= 3 && (
                    <React.Fragment>
                        <div className={classes.iconCircle}>
                            <img className={classes.icon} src={DIYIcon} alt="Do It Yourself" />
                        </div>
                        <Typography variant="h3">
                            Do It Yourself
                        </Typography>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

LoadingResults.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};


export default withStyles(Styles)(LoadingResults);
