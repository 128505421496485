import Breakpoints from '../../styles/breakpoints';
import Colors from '../../styles/colors';

const styles = theme => ({
    logo: {
        margin: 0,
        color: 'white',
        fontWeight: 'bold',
        fontSize: 26,
        '& span': {
            color: Colors.red,
        },
        '&:focus': {
            border: `1px solid ${Colors.yellow}`,
            marginLeft: -1,
        },
    },
    wrapper: {
        height: 70,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.royalBlue,

        [`@media (max-width: ${Breakpoints.sm}px)`]: {},
    },
    smallText: {
        fontFamily: theme.typography.body1.fontFamily,
        color: 'white',
        paddingRight: 10,
        paddingLeft: 5,
        '&:focus': {
            border: `1px solid ${Colors.yellow}`,
            marginLeft: -1,
        },
    },
    largeText: {
        fontFamily: theme.typography.body1.fontFamily,
        color: 'white',
        fontSize: 18,
        '&:focus': {
            border: `1px solid ${Colors.yellow}`,
            marginLeft: -1,
        },
    },
    activeText: {
        color: Colors.yellow,
    },
    covidBanner: {
        height: 50,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.yellow,

        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            height: 70,
        },
    },
    covidBannerText: {
        fontWeight: 600,
        color: Colors.royalBlue,
    },
    covidBannerLink: {
        color: 'black',
    },
});

export default styles;
