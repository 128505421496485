import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import Styles from './styles-questions';

const QuestionSpecialCircumstances = ({
    classes,
    setValidated,
    updateValue,
    defaultValue,
    data,
}) => {
    const [specialCircumstances, setSpecialCircumstances] = useState(defaultValue);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (specialCircumstances.length > 0) {
            setValidated(true);
        } else {
            setValidated(false);
        }
        updateValue(prevState => ({ ...prevState, specialCircumstances }));
    }, [specialCircumstances, updateValue, setValidated]);

    function handleSpecialClick(circumstance) {
        if (_.includes(specialCircumstances, circumstance)) {
            // remove from the array
            const specialsList = [...specialCircumstances];
            const index = specialsList.indexOf(circumstance);

            specialsList.splice(index, 1);
            setSpecialCircumstances(specialsList);
        } else if (circumstance === 7) {
            // we are adding 'none of these', so wipe out all other items in the list
            setSpecialCircumstances([7]);
        } else {
            // add to the array
            let specialsList = [...specialCircumstances];
            if (_.includes(specialCircumstances, 7)) {
                // if adding something else but 5 is already in the array, let's remove 5
                specialsList = [];
            }
            if (circumstance === 3) { // this is the domestic violence id
                setDialogOpen(true);
            }
            specialsList = specialsList.concat(circumstance);
            setSpecialCircumstances(specialsList);
        }
    }

    return (
        <React.Fragment>
            <SimpleDialog classes={classes} open={dialogOpen} onClose={() => setDialogOpen(false)} />
            <Typography variant="h1">
                Do any of the following apply?
            </Typography>
            <Typography variant="body2" style={{ paddingBottom: 30 }}>
                This site can be used by anyone, but this information will help determine appropriate resources for you.
            </Typography>
            <div className="row">
                <div className="col">
                    <div className="row">
                        {data.map((circumstance) => {
                            let selected = false;
                            if (_.includes(specialCircumstances, circumstance.id)) {
                                selected = true;
                            }
                            return (
                                <div className="col-md-6" key={circumstance.id}>
                                    <button
                                        key={circumstance.id}
                                        type="button"
                                        onClick={() => handleSpecialClick(circumstance.id)}
                                        style={{ width: '100%' }}
                                        className={`text-left ${classes.selectionButton} ${selected && classes.selectionButtonSelected}`}
                                    >
                                        <div className={selected ? classes.buttonCheckBoxSelected : classes.buttonCheckBox}>
                                            {selected ? <CheckIcon /> : ''}
                                        </div>
                                        <Typography className={classes.checkboxText} variant="body1">{circumstance.circumstance}</Typography>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

QuestionSpecialCircumstances.propTypes = {
    classes: PropTypes.object.isRequired,
    setValidated: PropTypes.func.isRequired,
    defaultValue: PropTypes.array.isRequired,
    updateValue: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
};

export default withStyles(Styles)(QuestionSpecialCircumstances);

function SimpleDialog(props) {
    const { onClose, classes, ...other } = props;

    return (
        <Dialog
            onClose={() => onClose()}
            {...other}
            classes={{
                paper: classes.modalPaper,
                paperFullScreen: classes.modalRoot,
            }}
            BackdropProps={{
                classes: { root: classes.modalBackdropRoot },
            }}
        >
            <div className="row text-right">
                <div className="col">
                    <button aira-label="close dialog" className={classes.modalCloseButton} type="button" onClick={() => onClose()}>
                        <ClearIcon />
                    </button>
                </div>
            </div>
            <div className="row text-center">
                <div className="col">
                    <Typography variant="h3" className={classes.modalTitle}>If you&rsquo;re in immediate danger call 911.</Typography>
                    <Typography variant="body1" className={classes.modalContent}>
                        If you&rsquo;re being hurt or abused, Colorado Adult Protective Services can assist you to get help, safety, and support.&nbsp;
                        <a href="https://www.coloradoaps.com/uploads/5/0/9/7/50975653/2018-10-4_aps_intake_numbers.pdf" target="_blank" rel="noopener noreferrer">Click here</a>
                        &nbsp;to find the phone number for help in your county.
                    </Typography>
                </div>
            </div>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
