import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';

import QuickExitDialog from './dialog';
import Styles from './styles';

const QuickExitComponent = ({ classes }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    return (
        <>
            <QuickExitDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
            <a className={classes.dialogLink} href="https://weather.com/" onClick={() => window.open('http://google.com', '_blank')}>
                <Typography variant="body1" className={classes.textLink}>Quick Exit</Typography>
            </a>
            <button type="button" className={classes.helpBtn} onClick={() => setDialogOpen(true)} aria-label="More info about Quick Exit">
                <HelpIcon className={classes.helpIcon} />
            </button>
        </>
    );
};

QuickExitComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(QuickExitComponent);
