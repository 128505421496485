import React, {
    useEffect,
    useState,
    useContext,
} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CheckIcon from '@material-ui/icons/Check';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import CTAButton from '../../components/button-cta';
import GetParameterByName from '../../utils/getParam';
import ValidEmail from '../../utils/validEmail';
import ValidPhone from '../../utils/validPhone';

import { history } from '../../store';
import Styles from './styles';
import IntakeDataContext from '../../wiring/intake-data-context';
import FontSizeContext from '../../wiring/font-size-context';
import EmailIcon from './images/icon_contact.png';

const ContactPartner = ({ classes, location }) => {
    const { resultsObj, inputObj } = useContext(IntakeDataContext);
    const { fontModifier } = useContext(FontSizeContext);

    const [formObj, setFormObj] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        accept: false,
        preferredContactMethod: 'phone',
    });
    const [formCompleted, setFormCompleted] = useState(false);
    const [selectedRecommendation, setSelectedRecommendation] = useState(-1);
    const [submitError, setSubmitError] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [radioValue, setRadioValue] = React.useState('phone');
    const [showContactPref, setShowContactPref] = useState(false);
    const [phoneValid, setPhoneValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);

    useEffect(() => {
        const recId = GetParameterByName('id', location.search);

        if (recId && Object.keys(resultsObj).length !== 0) {
            const selectedRecommendationVar = resultsObj.find(
                (rec) => rec.id.toString() === recId,
            );

            if (selectedRecommendationVar) {
                setSelectedRecommendation(selectedRecommendationVar);
            } else {
                history.push('/404');
            }
        } else {
            history.push('/404');
        }
    }, []);

    useEffect(() => {
        document.title = `Colorado Resource Network: Contact ${selectedRecommendation.name}`;
    }, [selectedRecommendation]);

    useEffect(() => {
        if (formObj.accept && formObj.firstName !== '' && formObj.lastName !== '' && formObj.phone !== '') {
            setFormCompleted(true);
        } else {
            setFormCompleted(false);
        }
        // if email is not empty, show the email
        if (formObj.email !== '') {
            setShowContactPref(true);
        }
    }, [formObj]);

    useEffect(() => {
        // if the email field is empty, let's make sure the radio button is reset to phone
        if (formObj.email === '') {
            if (document.getElementById('phoneradio')) {
                document.getElementById('phoneradio').click();
            }
            setShowContactPref(false);
        }
    }, [formObj.email]);

    const handleChange = name => event => {
        setFormObj({ ...formObj, [name]: event.target.value });
        if (name === 'phone') {
            setPhoneValid(true);
        } else if (name === 'email') {
            setEmailValid(true);
        }
    };
    const acceptTerms = (incomingValue) => {
        const setValue = !incomingValue;
        setFormObj({ ...formObj, accept: setValue });
    };
    const gotoResults = () => {
        history.push('/results');
    };
    const submitForm = async () => {
        setSubmitError(false);
        setIsSubmitting(true);
        let isValid = true;

        const dataForAPI = JSON.parse(JSON.stringify(formObj));

        if (!ValidPhone(dataForAPI.phone)) {
            isValid = false;
            setPhoneValid(false);
        }
        if (dataForAPI.email !== '' && !ValidEmail(dataForAPI.email)) {
            isValid = false;
            setEmailValid(false);
        }

        if (isValid) {
            try {
                delete dataForAPI.accept;
                if (dataForAPI.email === '') {
                    delete dataForAPI.email;
                }
                dataForAPI.specialCircumstances = inputObj.specialCircumstances;
                dataForAPI.parentIssueTypeId = inputObj.parentIssueTypeId;
                dataForAPI.childIssueTypeId = inputObj.issueTypeId;

                await axios.post(`${process.env.REACT_APP_API}/recommendations/${selectedRecommendation.id}/contact`, dataForAPI);

                setSubmitSuccess(true);
                history.push(`/contact-partner?id=${selectedRecommendation.id}&success=true`);
            } catch (error) {
                setSubmitError(true);
                history.push(`/contact-partner?id=${selectedRecommendation.id}&success=false`);
            }
        }
        setIsSubmitting(false);
    };
    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
        setFormObj({ ...formObj, preferredContactMethod: event.target.value });
    };

    let pageContent;
    if (submitSuccess) {
        pageContent = (
            <React.Fragment>
                <section className={classes.recommendationHeader}>
                    <div className="container d-flex" style={{ height: 58 }}>
                        <Button onClick={() => gotoResults()} className={classes.backBtn}>
                            <KeyboardBackspaceIcon />
                            &nbsp;Back to Results
                        </Button>
                    </div>
                </section>
                <div className="container" style={{ paddingBottom: 200 }}>
                    <div className="row">
                        <div className="col">
                            <section style={{ paddingBottom: 30 }}>
                                <Typography variant="h1">Success</Typography>
                                <Typography variant="body2" style={{ paddingBottom: 30 }}>
                                    Your information was successfully submitted to&nbsp;
                                    {selectedRecommendation.name}
                                    . You can go back and view more results or do another task.
                                </Typography>
                                <CTAButton onClick={() => gotoResults()} parentClass={classes.cancelButton}>
                                    Back to Results
                                </CTAButton>
                            </section>
                        </div>
                        <div className="col d-none d-md-flex justify-content-center align-items-start">
                            <img alt="email icon" src={EmailIcon} className={classes.emailIcon} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        pageContent = (
            <React.Fragment>
                <section className={classes.recommendationHeader}>
                    <div className="container d-flex" style={{ height: 58 }}>
                        <Button onClick={() => gotoResults()} className={classes.backBtn}>
                            <KeyboardBackspaceIcon />
                            &nbsp;Back to Results
                        </Button>
                    </div>
                </section>
                <div className="container">
                    {submitError && (
                        <div className={`row ${classes.formRow}`}>
                            <div className="col">
                                <span role="status">
                                    <Typography variant="body2" className={classes.errorMsg}>
                                        There was an error submitting your form, please try again.
                                    </Typography>
                                </span>
                            </div>
                        </div>
                    )}
                    <span role="status" className={classes.screenReaderOnly}>
                        {!emailValid && <Typography variant="body1">Error: Invalid Email</Typography>}
                        {!phoneValid && <Typography variant="body1">Error: Invalid Phone Number</Typography>}
                    </span>
                    <div className="row">
                        <div className="col">
                            <section style={{ paddingBottom: 30 }}>
                                <Typography variant="h1">
                                    Contact
                                    {' '}
                                    {selectedRecommendation.name}
                                </Typography>
                                <Typography variant="body2" style={{ paddingBottom: 30 }}>
                                    Complete the form and we will connect you with the selected partner for more details.
                                </Typography>
                                <div className={`row ${classes.formRow}`}>
                                    <div className="col-6">
                                        <TextField
                                            label="First Name"
                                            variant="outlined"
                                            type="text"
                                            autoComplete="given-name"
                                            fullWidth
                                            onChange={handleChange('firstName')}
                                            InputLabelProps={{
                                                classes: {
                                                    root: fontModifier === 0 ? classes.cssLabel : classes.cssLabelLg,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: fontModifier === 0 ? classes.cssOutlinedInput : classes.cssOutlinedInputLg,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                inputProps: {
                                                    'aria-label': 'First Name',
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextField
                                            label="Last Name"
                                            variant="outlined"
                                            type="text"
                                            autoComplete="family-name"
                                            fullWidth
                                            onChange={handleChange('lastName')}
                                            InputLabelProps={{
                                                classes: {
                                                    root: fontModifier === 0 ? classes.cssLabel : classes.cssLabelLg,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: fontModifier === 0 ? classes.cssOutlinedInput : classes.cssOutlinedInputLg,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                inputProps: {
                                                    'aria-label': 'Last Name',
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.formRow}>
                                    <TextField
                                        error={!phoneValid}
                                        label="Phone"
                                        variant="outlined"
                                        type="text"
                                        autoComplete="phone"
                                        fullWidth
                                        onChange={handleChange('phone')}
                                        InputLabelProps={{
                                            classes: {
                                                root: fontModifier === 0 ? classes.cssLabel : classes.cssLabelLg,
                                                focused: classes.cssFocused,
                                            },
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: fontModifier === 0 ? classes.cssOutlinedInput : classes.cssOutlinedInputLg,
                                                focused: classes.cssFocused,
                                                notchedOutline: classes.notchedOutline,
                                            },
                                            inputProps: {
                                                'aria-label': 'Phone',
                                            },
                                        }}
                                    />
                                    <span>
                                        {!phoneValid && <Typography variant="body1" className={classes.errorMsg} style={{ marginTop: 5 }}>Error: Invalid Phone Number</Typography>}
                                    </span>
                                </div>
                                <div className={classes.formRow}>
                                    <TextField
                                        error={!emailValid}
                                        label="Email (Optional)"
                                        variant="outlined"
                                        type="text"
                                        autoComplete="email"
                                        fullWidth
                                        onChange={handleChange('email')}
                                        InputLabelProps={{
                                            classes: {
                                                root: fontModifier === 0 ? classes.cssLabel : classes.cssLabelLg,
                                                focused: classes.cssFocused,
                                            },
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: fontModifier === 0 ? classes.cssOutlinedInput : classes.cssOutlinedInputLg,
                                                focused: classes.cssFocused,
                                                notchedOutline: classes.notchedOutline,
                                                error: classes.notchedOutlineError,
                                            },
                                            inputProps: {
                                                'aria-label': 'Email Address',
                                            },
                                        }}
                                    />
                                    <span>
                                        {!emailValid && <Typography variant="body1" className={classes.errorMsg} style={{ marginTop: 5 }}>Error: Invalid Email</Typography>}
                                    </span>
                                </div>
                                {showContactPref && (
                                    <div className={classes.formRow}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend"><Typography variant="body1">How would you prefer to be contacted?</Typography></FormLabel>
                                            <RadioGroup aria-label="Contact preference" name="contact-pref" value={radioValue} onChange={handleRadioChange} row>
                                                <FormControlLabel
                                                    id="phoneradio"
                                                    value="phone"
                                                    control={
                                                        <Radio color="secondary" inputProps={{ 'aria-label': 'Phone' }} />
                                                    }
                                                    label={<Typography variant="body1">Phone</Typography>}
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value="email"
                                                    control={
                                                        <Radio color="secondary" inputProps={{ 'aria-label': 'Email' }} />
                                                    }
                                                    label={<Typography variant="body1">Email</Typography>}
                                                    labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                )}
                                <div className={classes.formRow}>
                                    <button
                                        aria-label="Accept terms of submission"
                                        type="button"
                                        onClick={() => acceptTerms(formObj.accept)}
                                        className={`text-left ${classes.selectionButton} ${formObj.accept && classes.selectionButtonSelected}`}
                                    >
                                        <div className={formObj.accept ? classes.buttonCheckBoxSelected : classes.buttonCheckBox}>
                                            {formObj.accept ? <CheckIcon /> : ''}
                                        </div>
                                    </button>
                                    <Typography variant="body1" style={{ display: 'inline', paddingLeft: 15 }}>I acknowledge that this will send my answers from this form to the service provider listed above.</Typography>
                                </div>
                                <div className={`row ${classes.formRow}`}>
                                    <div className="col-6">
                                        <CTAButton onClick={() => gotoResults()} parentClass={classes.cancelButton}>
                                            Cancel
                                        </CTAButton>
                                    </div>
                                    <div className="col-6">
                                        {!isSubmitting && (
                                            <CTAButton onClick={() => submitForm()} disabled={!formCompleted}>
                                                Submit
                                            </CTAButton>
                                        )}
                                        {isSubmitting && (
                                            <CTAButton disabled>
                                                Submitting
                                            </CTAButton>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="col d-none d-md-flex justify-content-center align-items-center">
                            <img alt="email icon" src={EmailIcon} className={classes.emailIcon} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    return pageContent;
};

ContactPartner.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default withStyles(Styles)(ContactPartner);
