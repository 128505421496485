import React from 'react';
import { Switch, Route } from 'react-router-dom';
import withRoot from '../../wiring/withRoot';

import Loading from '../../routes/loading';

const EmptyLayout = () => (
    <Route
        render={({ location }) => (
            <React.Fragment>
                <div className="container">
                    <Switch location={location}>
                        <Route exact path="/loading" component={Loading} />
                    </Switch>
                </div>
            </React.Fragment>
        )}
    />
);

export default withRoot(EmptyLayout);
