import Breakpoints from '../../styles/breakpoints';
import Colors from '../../styles/colors';

const styles = ({
    wrapper: {
        height: 70,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.royalBlue,
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            height: 'auto',
            padding: '10px 0',
        },
    },
    footerLink: {
        padding: '0 15px',
        display: 'inline-flex',
        '& a': {
            color: 'white',
        },
        [`@media (max-width: ${Breakpoints.md}px)`]: {
            padding: '5px 0',
        },
    },
    copyright: {
        color: 'white',
        display: 'inline-flex',
        padding: '0 15px',
    },
});

export default styles;
