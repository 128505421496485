import { createMuiTheme } from '@material-ui/core/styles';
import Colors from './colors';
import Breakpoints from './breakpoints';

// https://material-ui.com/customization/default-theme/?expend-path=$.palette.background
const Theme = fontModifier => createMuiTheme({
    typography: {
        useNextVariants: true,
        body1: {
            color: Colors.lightBlue,
            fontFamily: '\'Nunito Sans\', sans-serif',
            fontSize: 17 + fontModifier,
            lineHeight: '1.4em',
            [`@media (max-width: ${Breakpoints.lg}px)`]: {
                fontSize: 16 + fontModifier,
            },
            [`@media (max-width: ${Breakpoints.sm}px)`]: {
                fontSize: 15 + fontModifier,
            },
        },
        body2: {
            color: Colors.lightBlue,
            fontFamily: '\'Nunito Sans\', sans-serif',
            fontSize: 20 + fontModifier,
            [`@media (max-width: ${Breakpoints.lg}px)`]: {
                fontSize: 19 + fontModifier,
            },
            [`@media (max-width: ${Breakpoints.sm}px)`]: {
                fontSize: 18 + fontModifier,
            },
        },
        h1: {
            color: Colors.royalBlue,
            fontFamily: '\'Nunito Sans\', sans-serif',
            fontSize: 32 + fontModifier,
            lineHeight: `${32 + fontModifier}px`,
            padding: '15px 0',
            fontWeight: 800,
            [`@media (max-width: ${Breakpoints.md}px)`]: {
                fontSize: 28 + fontModifier,
                lineHeight: '40px',
            },
            [`@media (max-width: ${Breakpoints.sm}px)`]: {
                fontSize: 24 + fontModifier,
            },
        },
        h2: {
            fontFamily: '\'Nunito Sans\', sans-serif',
            color: Colors.royalBlue,
            fontSize: 24 + fontModifier,
            fontWeight: 800,
            padding: '15px 0',
            [`@media (max-width: ${Breakpoints.md}px)`]: {
                fontSize: 22 + fontModifier,
            },
            [`@media (max-width: ${Breakpoints.sm}px)`]: {
                fontSize: 20 + fontModifier,
            },
        },
        h3: {
            fontFamily: '\'Nunito Sans\', sans-serif',
            color: Colors.royalBlue,
            fontSize: 21 + fontModifier,
            fontWeight: 'bold',
            padding: '15px 0',
            lineHeight: '1.4em',
            [`@media (max-width: ${Breakpoints.md}px)`]: {
                fontSize: 20 + fontModifier,
            },
            [`@media (max-width: ${Breakpoints.sm}px)`]: {
                fontSize: 18 + fontModifier,
            },
        },
        h4: {
            fontFamily: '\'Nunito Sans\', sans-serif',
            color: Colors.royalBlue,
            fontSize: 18 + fontModifier,
            fontWeight: 'bold',
            [`@media (max-width: ${Breakpoints.md}px)`]: {
                fontSize: 17 + fontModifier,
            },
            [`@media (max-width: ${Breakpoints.sm}px)`]: {
                fontSize: 16 + fontModifier,
            },
        },
    },
    palette: {
        primary: {
            main: Colors.royalBlue,
        },
        secondary: {
            main: Colors.iconBlue,
        },
        text: {
            secondary: Colors.demo,
        },
        background: {
            default: '#EFF5FF',
        },
        error: {
            main: Colors.red,
        },
    },
});


export default Theme;
