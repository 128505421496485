import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LanguageIcon from '@material-ui/icons/Language';
import LaunchIcon from '@material-ui/icons/Launch';

import CTAButton from '../../components/button-cta';
import { history } from '../../store';
import Styles from './styles';
import IntakeDataContext from '../../wiring/intake-data-context';

const Recommendation = ({ recId, classes }) => {
    const { resultsObj } = useContext(IntakeDataContext);
    const [recommendation, setRecommendation] = useState({ officeHours: [] });
    const [showOfficeHours, setShowOfficeHours] = useState(false);

    useEffect(() => {
        // check if we had results data we can use to build from
        // if we don't have results data, push the user to the homepage
        if (Object.keys(resultsObj).length === 0) {
            history.push('/');
        } else {
            const selectedRecommendation = resultsObj.find(
                (rec) => rec.id === recId,
            );
            setRecommendation(selectedRecommendation);
            setShowOfficeHours(false);
        }
    }, [resultsObj, recId]);

    const contactPartner = (id) => {
        history.push(`/contact-partner?id=${id}`);
    };

    return (
        <React.Fragment>
            <section className={classes.wrapper}>
                <div className={`${classes.headerTextBlock} ${classes.contactTextBlock}`}>
                    <Typography
                        variant="h1"
                        className={classes.organizationTitle}
                    >
                        {recommendation.name}
                    </Typography>
                    {recommendation.email && (
                        <CTAButton parentClass={classes.actionButton} onClick={() => contactPartner(recommendation.id)}>
                            Contact this Partner
                        </CTAButton>
                    )}
                </div>
                {recommendation.phone && (
                    <div className={`row ${classes.contactTextBlock}`}>
                        <div className="col-1">
                            <PhoneIcon
                                className={classes.contactIcons}
                            />
                        </div>
                        <div className="col">
                            <Typography variant="body1">
                                {recommendation.phone}
                            </Typography>
                        </div>
                    </div>
                )}
                {recommendation.address1 && (
                    <div className={`row ${classes.contactTextBlock}`}>
                        <div className="col-1">
                            <LocationOnIcon
                                className={classes.contactIcons}
                            />
                        </div>
                        <div className="col">
                            <a
                                href={`http://maps.google.com/?q=${recommendation.address1} ${recommendation.city}, ${recommendation.state} ${recommendation.zip}`}
                                rel="noopener noreferrer"
                                target="_blank"
                                className={classes.textLink}
                            >
                                <Typography variant="body1">
                                    {recommendation.address1}
                                </Typography>
                                {recommendation.address2 && (
                                    <Typography variant="body1">
                                        {recommendation.address2}
                                    </Typography>
                                )}
                                <Typography
                                    style={{ textTransform: 'capitalize' }}
                                    variant="body1"
                                >
                                    {recommendation.city}
                                    {', '}
                                    {recommendation.state}
                                    {' '}
                                    {recommendation.zip}
                                    <LaunchIcon className={classes.launchIcon} />
                                </Typography>
                            </a>
                        </div>
                    </div>
                )}
                {recommendation.website && (
                    <div className={`row ${classes.contactTextBlock}`}>
                        <div className="col-1">
                            <LanguageIcon
                                className={classes.contactIcons}
                            />
                        </div>
                        <div className="col">
                            <Typography variant="body1">
                                <a
                                    href={
                                        recommendation.website
                                    }
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className={classes.textLink}
                                >
                                    {recommendation.website}
                                    <LaunchIcon className={classes.launchIcon} />
                                </a>
                            </Typography>
                        </div>
                    </div>
                )}
                {recommendation.officeHours && (
                    <div
                        className={`row ${
                            classes.contactTextBlock
                        }`}
                    >
                        <div className="col-1">
                            {showOfficeHours && <AccessTimeIcon className={classes.contactIcons} /> }
                        </div>
                        <div className="col">
                            {recommendation.officeHours.map((hours) => {
                                const day = Object.keys(hours);
                                if (hours[day].active) {
                                    if (!showOfficeHours) {
                                        setShowOfficeHours(true);
                                    }
                                    return (
                                        <div
                                            className="row no-gutters"
                                            key={`officeHours-${day}`}
                                        >
                                            <div
                                                className="col"
                                                style={{
                                                    maxWidth: 125,
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {day}
                                                </Typography>
                                            </div>
                                            <div className="col">
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                    key={`officeHours-${day}`}
                                                >
                                                    {hours[day].open}
                                                    &nbsp; -&nbsp;
                                                    {hours[day].close}
                                                </Typography>
                                            </div>
                                        </div>
                                    );
                                }
                                return false;
                            })}
                        </div>
                    </div>
                )}
                <hr className={classes.breakReturn} />
                <div className={classes.contactTextBlock}>
                    <Typography variant="body1">
                        {recommendation.description}
                    </Typography>
                </div>
                {recommendation.expect && (
                    <React.Fragment>
                        <hr className={classes.breakReturn} />
                        <div className={classes.contactTextBlock}>
                            <Typography variant="h4" style={{ paddingBottom: 10 }}>What to expect</Typography>
                            <Typography variant="body1">
                                {recommendation.expect}
                            </Typography>
                        </div>
                    </React.Fragment>
                )}
                {recommendation.costs && (
                    <React.Fragment>
                        <hr className={classes.breakReturn} />
                        <div className={classes.contactTextBlock}>
                            <Typography variant="h4" style={{ paddingBottom: 10 }}>Costs</Typography>
                            <Typography variant="body1">
                                {recommendation.costs}
                            </Typography>
                        </div>
                    </React.Fragment>
                )}
            </section>
        </React.Fragment>
    );
};

Recommendation.propTypes = {
    recId: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(Recommendation);
