import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import LaunchIcon from '@material-ui/icons/Launch';

import { Transition } from 'react-transition-group';

import { history } from '../../store';
import QuickExitDialog from '../../components/quick-exit';
import Styles from './styles';
import IntakeDataContext from '../../wiring/intake-data-context';
import CommunityIcon from './images/community.png';
import LegalIcon from './images/legal.png';
import DIYIcon from './images/diy.png';

import Recommmendation from '../recommendation/recommendation-drawer-content';

const Results = ({ classes }) => {
    const { resultsObj } = useContext(IntakeDataContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);

    const [communityHelpObj, setCommunityHelpObj] = useState([]);
    const [legalHelpObj, setLegalHelpObj] = useState([]);
    const [selfHelpObj, setSelfHelpObj] = useState([]);

    useEffect(() => {
        if (Object.keys(resultsObj).length === 0) {
            history.push('/');
        }
        document.title = 'Colorado Resource Network: Your Recommendations';

        const communityHelpTemp = [];
        const legalHelpTemp = [];
        const selfHelpTemp = [];

        resultsObj.map((recommendation) => {
            if (recommendation.type === 'Community Help') {
                communityHelpTemp.push(recommendation);
            } else if (recommendation.type === 'Legal Help') {
                legalHelpTemp.push(recommendation);
            } else if (recommendation.type === 'Do It Yourself') {
                selfHelpTemp.push(recommendation);
            }
            return false;
        });
        setCommunityHelpObj(communityHelpTemp);
        setLegalHelpObj(legalHelpTemp);
        setSelfHelpObj(selfHelpTemp);
    }, [resultsObj]); // on initial render, check if we have results ready (we have to)

    const toggleDrawer = (open, recommendationId) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
        if (open) {
            setSelectedId(recommendationId);
        }
    };

    const gotoIntake = () => {
        history.push('/intake');
    };
    const duration = { enter: 300, exit: 500 };
    const transitionDelay = 500;

    const truncate = (word, n, useWordBoundary) => {
        if (word.length <= n) { return word; }
        const subString = word.substr(0, n - 1);
        return `${useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString} ...`;
    };
    return (
        <React.Fragment>
            <div className="container">
                <div className="row no-gutters">
                    <div className="col text-right pt-3">
                        <QuickExitDialog />
                    </div>
                </div>
                <Typography variant="h1" className={`text-left text-md-center ${classes.resultHeader}`}>Here&rsquo;s what&rsquo;s available in your community</Typography>
                {communityHelpObj.length > 0 && (
                    <section className={classes.wrapper}>
                        <Transition appear timeout={duration}>
                            {(state) => (
                                <div
                                    className={`${classes.resultSection}
                                            ${state === 'entering'
                                                && classes.resultSectionEntering}
                                            ${state === 'entered'
                                                && classes.resultSectionEntered}`}
                                    style={{ transitionDelay: `${transitionDelay}ms` }}
                                >
                                    <div className={`${classes.resultBlock} row no-gutters`}>
                                        <div className="col-md-4 text-md-left text-center">
                                            <Typography variant="h2">
                                                Community Help
                                            </Typography>
                                            <img src={CommunityIcon} alt="community icon" className={classes.resultsBlockIcon} />
                                            <hr className={`d-block d-md-none ${classes.breakReturn}`} />
                                        </div>
                                        <div className="col-md-8">
                                            {communityHelpObj.map((recommendation, i) => {
                                                return (
                                                    <React.Fragment
                                                        key={`recommendation-${
                                                            recommendation.id
                                                        }`}
                                                    >
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className={classes.resultListing}>
                                                                    <Typography
                                                                        variant="h3"
                                                                        className={
                                                                            classes.resultName
                                                                        }
                                                                    >
                                                                        {recommendation.name}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.resultDescription}
                                                                    >
                                                                        {truncate(recommendation.description, 150, true)}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto d-flex justify-content-center align-items-center">
                                                                <Button
                                                                    variant="contained"
                                                                    className={
                                                                        classes.gotoResultsButton
                                                                    }
                                                                    onClick={toggleDrawer(true, recommendation.id)}
                                                                    aria-label={`View ${recommendation.name}`}
                                                                    role="button"
                                                                >
                                                                    <KeyboardArrowRightIcon
                                                                        className={
                                                                            classes.resultBlockIcon
                                                                        }
                                                                    />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        {i + 1 !== communityHelpObj.length
                                                            && <hr className={classes.breakReturn} />
                                                        }
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </section>
                )}
                {legalHelpObj.length > 0 && (
                    <section className={classes.wrapper}>
                        <Transition appear timeout={duration}>
                            {(state) => (
                                <div
                                    className={`${classes.resultSection}
                                            ${state === 'entering'
                                                && classes.resultSectionEntering}
                                            ${state === 'entered'
                                                && classes.resultSectionEntered}`}
                                    style={{ transitionDelay: `${transitionDelay}ms` }}
                                >

                                    <div className={`${classes.resultBlock} row no-gutters`}>
                                        <div className="col-md-4 text-md-left text-center">
                                            <Typography variant="h2">
                                                Legal Help
                                            </Typography>
                                            <img src={LegalIcon} alt="legal icon" className={classes.resultsBlockIcon} />
                                            <hr className={`d-block d-md-none ${classes.breakReturn}`} />
                                        </div>
                                        <div className="col-md-8">
                                            {legalHelpObj.map((recommendation, i) => {
                                                return (
                                                    <React.Fragment
                                                        key={`recommendation-${
                                                            recommendation.id
                                                        }`}
                                                    >
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className={classes.resultListing}>
                                                                    <Typography
                                                                        variant="h3"
                                                                        className={
                                                                            classes.resultName
                                                                        }
                                                                    >
                                                                        {recommendation.name}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.resultDescription}
                                                                    >
                                                                        {truncate(recommendation.description, 150, true)}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto d-flex justify-content-center align-items-center">
                                                                <Button
                                                                    aria-label={`View ${recommendation.name}`}
                                                                    role="button"
                                                                    variant="contained"
                                                                    className={
                                                                        classes.gotoResultsButton
                                                                    }
                                                                    onClick={toggleDrawer(true, recommendation.id)}
                                                                >
                                                                    <KeyboardArrowRightIcon
                                                                        className={
                                                                            classes.resultBlockIcon
                                                                        }
                                                                    />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        {i + 1 !== legalHelpObj.length
                                                            && <hr className={classes.breakReturn} />
                                                        }
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </section>
                )}

                {selfHelpObj.length > 0 && (
                    <section className={classes.wrapper}>
                        <Transition appear timeout={duration}>
                            {(state) => (
                                <div
                                    className={`${classes.resultSection}
                                            ${state === 'entering'
                                                && classes.resultSectionEntering}
                                            ${state === 'entered'
                                                && classes.resultSectionEntered}`}
                                    style={{ transitionDelay: `${transitionDelay}ms` }}
                                >

                                    <div className={`${classes.resultBlock} row no-gutters`}>
                                        <div className="col-md-4 text-md-left text-center">
                                            <Typography variant="h2">
                                                Do It Yourself
                                            </Typography>
                                            <img src={DIYIcon} alt="legal icon" className={classes.resultsBlockIcon} />
                                            <hr className={`d-block d-md-none ${classes.breakReturn}`} />
                                        </div>
                                        <div className="col-md-8">
                                            {selfHelpObj.map((recommendation, i) => {
                                                return (
                                                    <React.Fragment
                                                        key={`recommendation-${
                                                            recommendation.id
                                                        }`}
                                                    >
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className={classes.resultListing}>
                                                                    <Typography
                                                                        variant="h3"
                                                                        className={
                                                                            classes.resultName
                                                                        }
                                                                    >
                                                                        {recommendation.name}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.resultDescription}
                                                                    >
                                                                        {truncate(recommendation.description, 140, true)}
                                                                    </Typography>
                                                                    {recommendation.website && (
                                                                        <Typography variant="body1" style={{ paddingTop: 15 }}>
                                                                            <a
                                                                                href={
                                                                                    recommendation.website
                                                                                }
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                                className={classes.textLink}
                                                                            >
                                                                                {recommendation.website}
                                                                                <LaunchIcon className={classes.launchIcon} />
                                                                            </a>
                                                                        </Typography>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-auto d-flex justify-content-center align-items-center">
                                                                <Button
                                                                    aria-label={`View ${recommendation.name}`}
                                                                    role="button"
                                                                    variant="contained"
                                                                    className={
                                                                        classes.gotoResultsButton
                                                                    }
                                                                    onClick={toggleDrawer(true, recommendation.id)}
                                                                >
                                                                    <KeyboardArrowRightIcon
                                                                        className={
                                                                            classes.resultBlockIcon
                                                                        }
                                                                    />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        {i + 1 !== selfHelpObj.length
                                                            && <hr className={classes.breakReturn} />
                                                        }
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </section>
                )}
            </div>
            <section className={classes.resultsFooter}>
                <div className="container d-flex" style={{ height: 58 }}>
                    <Button onClick={() => gotoIntake()} className={classes.backBtn}>
                        <KeyboardBackspaceIcon />
                        &nbsp;Start over or search for a different issue
                    </Button>
                </div>
            </section>
            <Drawer
                classes={{
                    paper: classes.recommendationDrawerPaper,
                }}
                className={classes.recommendationDrawer}
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <div className={classes.drawerCloseWrapper}>
                    <button aria-label="close drawer" className={classes.drawerCloseButton} type="button" onClick={toggleDrawer(false)}>
                        <ClearIcon />
                    </button>
                </div>
                <Recommmendation recId={selectedId} />
            </Drawer>
        </React.Fragment>
    );
};

Results.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(Results);
