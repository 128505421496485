import Colors from '../../styles/colors';

const styles = theme => ({
    wrapper: {
        color: theme.palette.text.secondary,
        '@media (max-width: 740px)': {},
    },
    cancelButton: {
        backgroundColor: Colors.backgroundBlue,
        border: `2px solid ${Colors.borderGrey}`,
        '&:hover': {
            backgroundColor: Colors.lightBlue,
            color: 'white',
            border: 0,
        },
    },
    errorHeader: {
        marginBottom: 40,
        backgroundColor: 'white',
    },
    errorIcon: {
        height: 250,
    },
});
export default styles;
