import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { history } from '../../store';
import CTAButton from '../../components/button-cta';
import QuickExitDialog from '../../components/quick-exit';
import Styles from './styles';

import TopSectionImage1 from './images/1.png';
import TopSectionImage2 from './images/2.png';
import TopSectionImage3 from './images/3.png';
import TopSectionImage4 from './images/4.png';
import TopSectionImage5 from './images/5.png';
import TopSectionImage6 from './images/6.png';
import TopSectionImage7 from './images/7.png';
import MiddleSectionImage from './images/infographic.png';

import Partner1 from './images/homepage-partner-1.png';
import Partner2 from './images/homepage-partner-2.png';
import Partner3 from './images/homepage-partner-3.png';
import Partner4 from './images/homepage-partner-4.png';

import QuoteImg1 from './images/testimonial-kaylene.png';
import QuoteImg2 from './images/testimonial-john.png';

const Home = ({ classes }) => {
    useEffect(() => {
        document.title = 'Colorado Resource Network: Home';
    }, []);

    const gotoIntake = () => {
        history.push('/intake');
    };

    const homepageNumber = Math.floor(Math.random() * Math.floor(7));

    return (
        <React.Fragment>
            <section className={classes.topSection}>
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col text-right pt-3">
                            <QuickExitDialog />
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className={`col-lg-7 ${classes.topSectionContentWrapper}`}>
                            <Typography variant="h1" className={classes.introTitle}>
                                Legal and Community Help
                                <br />
                                for Coloradans
                            </Typography>
                            <Typography variant="body2">Answer some questions to find the right referrals and resources.</Typography>
                            <CTAButton onClick={() => gotoIntake()} parentClass={classes.actionButton}>
                                Start Here
                                <KeyboardBackspaceIcon className={classes.hideOnMobile} style={{ transform: 'rotate(180deg)', marginLeft: 5 }} />
                            </CTAButton>
                        </div>
                        <div className="d-none d-lg-block col-lg-5" style={{ alignSelf: 'flex-end' }}>
                            {homepageNumber === 0
                                && <img src={TopSectionImage1} alt="" className={classes.topSectionImage} />
                            }
                            {homepageNumber === 1
                                && <img src={TopSectionImage2} alt="" className={classes.topSectionImage} />
                            }
                            {homepageNumber === 2
                                && <img src={TopSectionImage3} alt="" className={classes.topSectionImage} />
                            }
                            {homepageNumber === 3
                                && <img src={TopSectionImage4} alt="" className={classes.topSectionImage} />
                            }
                            {homepageNumber === 4
                                && <img src={TopSectionImage5} alt="" className={classes.topSectionImage} />
                            }
                            {homepageNumber === 5
                                && <img src={TopSectionImage6} alt="" className={classes.topSectionImage} />
                            }
                            {homepageNumber === 6
                                && <img src={TopSectionImage7} alt="" className={classes.topSectionImage} />
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ backgroundColor: 'white' }}>
                <div className="container">
                    <div className="row no-gutters" style={{ padding: '40px 0' }}>
                        <div className={`col-lg-6 ${classes.howItWorksLeftCol}`}>
                            <Typography variant="h2" className="text-center text-lg-left">How it Works</Typography>
                            <Typography variant="body2" className="text-center text-lg-left">Answer a few simple questions to find resources on topics such as benefits &amp; healthcare, families, health &amp; wellness, housing, money, wills &amp; estates.</Typography>
                            <img src={MiddleSectionImage} alt="" className={`${classes.hideOnDesktop} ${classes.middleSectionImageMobile}`} />
                            <div className={`row ${classes.howItWorksBlock}`}>
                                <div className="col-auto">
                                    <DoneIcon className={classes.checkIcon} />
                                </div>
                                <div className="col">
                                    <Typography variant="h4" className={classes.howItWorksBlockSubTitle}>Do It Yourself</Typography>
                                    <Typography variant="body1">Find online information and tools to help you get started at any time</Typography>
                                </div>
                            </div>
                            <div className={`row ${classes.howItWorksBlock}`}>
                                <div className="col-auto">
                                    <DoneIcon className={classes.checkIcon} />
                                </div>
                                <div className="col">
                                    <Typography variant="h4" className={classes.howItWorksBlockSubTitle}>Community Help</Typography>
                                    <Typography variant="body1">Find community services near you</Typography>
                                </div>
                            </div>
                            <div className={`row ${classes.howItWorksBlock}`}>
                                <div className="col-auto">
                                    <DoneIcon className={classes.checkIcon} />
                                </div>
                                <div className="col">
                                    <Typography variant="h4" className={classes.howItWorksBlockSubTitle}>Legal Help</Typography>
                                    <Typography variant="body1">Find a self-help center or lawyer</Typography>
                                </div>
                            </div>
                        </div>
                        <div className={`col-lg-6 ${classes.hideOnMobile}`} style={{ textAlign: 'center' }}>
                            <img src={MiddleSectionImage} alt="" className={classes.middleSectionImage} />
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col text-center">
                            <Typography variant="h2">Used by Coloradans in Your Community</Typography>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg" style={{ paddingTop: 60, paddingBottom: 60 }}>
                            <p className={classes.quotation}>&ldquo;</p>
                            <Typography variant="h3" className={classes.quoteBlockText}>As a retired man in my mid-70s, I find that I often get confused when I need information, help, or resources related to the social, governmental or private sectors. I can search on the internet for different topics, but they are often confusing, incomplete, or not related to where I live. The Colorado Resource Network puts all the information in one place, is easy to use and gives me the information I need. I am grateful for this resource!</Typography>
                            <div className={`row ${classes.quoterBlock}`}>
                                <div className="col-auto">
                                    <img src={QuoteImg2} alt="" className={classes.quoteImg} />
                                </div>
                                <div className="col">
                                    <Typography variant="body1">John T, Retired Non-Profit Consultant</Typography>
                                    <Typography variant="body1">Colorado Springs</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg" style={{ paddingTop: 60, paddingBottom: 60 }}>
                            <div className={classes.quotation}>&ldquo;</div>
                            <Typography variant="h3" className={classes.quoteBlockText}>Our hope with the Colorado Resource Network was to gain more resources for our area, but what it has done is open up communications for the resources that we currently have. As this work continues to develop I believe that assistance in the 12th Judicial District will continue to increase and will provide additional help for members of our community.</Typography>
                            <div className={`row ${classes.quoterBlock}`}>
                                <div className="col-auto">
                                    <img src={QuoteImg1} alt="" className={classes.quoteImg} />
                                </div>
                                <div className="col">
                                    <Typography variant="body1">Kaylene G, Self Represented Litigant Coordinator</Typography>
                                    <Typography variant="body1">Alamosa Courts, 12th Judicial District</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={classes.bottomSection}>
                <div className="container">
                    <div className={`row text-center no-gutters ${classes.bottomSectionContentWrapper}`}>
                        <div className="col">
                            <Typography variant="h2">About Colorado Resource Network</Typography>
                            <Typography variant="body1">Colorado Resource Network helps older Coloradans and their caregivers locate information, connect with service providers and obtain assistance in areas that are important to them. This resource was created through the Justice for All project which is funded by The Kresge Foundation, Public Welfare Foundation and Open Society Foundations, and is administered by the National Center for State Courts. The State Justice Institute provided additional funding to complete the first phase of development. Colorado&rsquo;s Justice for All project is a collaborative partnership of the Access to Justice Commission, the Colorado Judicial Department and the Colorado Bar Association.</Typography>
                        </div>
                    </div>
                    <div className="row text-center" style={{ padding: '0 20% 60px 20%' }}>
                        <div className="col-6 col-md-3">
                            <a href="http://www.coloradojustice.org/" target="_blank" rel="noopener noreferrer">
                                <img src={Partner1} alt="Colorado Access to Justic Commission" className={classes.partnerImage} />
                            </a>
                        </div>
                        <div className="col-6 col-md-3">
                            <a href="http://www.sji.gov/" target="_blank" rel="noopener noreferrer">
                                <img src={Partner2} alt="State Justice Institute" className={classes.partnerImage} />
                            </a>
                        </div>
                        <div className="col-6 col-md-3">
                            <a href="https://www.courts.state.co.us/" target="_blank" rel="noopener noreferrer">
                                <img src={Partner3} alt="Colorado Courts" className={classes.partnerImage} />
                            </a>
                        </div>
                        <div className="col-6 col-md-3">
                            <a href="https://www.cobar.org/" target="_blank" rel="noopener noreferrer">
                                <img src={Partner4} alt="Colorado Bar Association" className={classes.partnerImage} />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(Home);
