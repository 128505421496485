import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LaunchIcon from '@material-ui/icons/Launch';

import NoResultsIcon from './images/icon-no-results.png';
import CTAButton from '../../components/button-cta';
import { history } from '../../store';
import Styles from './styles';

const NoResults = ({
    classes,
}) => {
    const GoHome = () => {
        history.push('/');
    };
    return (
        <div className="container display-flex" style={{ paddingTop: 50, paddingBottom: 100 }}>
            <Typography variant="h1" style={{ paddingBottom: 30 }}>
                No Results Found
            </Typography>
            <div className="row">
                <div className="col-12 col-md-7">
                    <section style={{ paddingBottom: 30 }}>
                        <Typography variant="body2">
                            We&rsquo;re sorry, it seems we cannot find any results for you based on the specific location and issue type. You can try again by entering a different location or issue.
                        </Typography>
                        <Typography variant="body2" style={{ paddingTop: 15 }}>
                            If you are unable to find help through this website please check out some of these links for older Coloradans.
                        </Typography>
                        <Typography variant="body1" style={{ paddingTop: 30 }}>
                            <a
                                href="http://google.com"
                                rel="noopener noreferrer"
                                target="_blank"
                                className={classes.textLink}
                            >
                                Website 1
                                <LaunchIcon className={classes.launchIcon} />
                            </a>
                        </Typography>
                        <Typography variant="body1" style={{ paddingTop: 15 }}>
                            <a
                                href="http://google.com"
                                rel="noopener noreferrer"
                                target="_blank"
                                className={classes.textLink}
                            >
                                Website 2
                                <LaunchIcon className={classes.launchIcon} />
                            </a>
                        </Typography>
                    </section>
                    <CTAButton parentClass={classes.actionButton} onClick={() => GoHome()}>
                        Start Over
                    </CTAButton>
                </div>
                <div className="col d-none d-md-flex justify-content-center align-items-start">
                    <img alt="question icon" src={NoResultsIcon} style={{ width: '100%', maxWidth: 300 }} />
                </div>
            </div>
        </div>
    );
};

NoResults.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(NoResults);
