import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import _ from 'lodash';

import { history } from '../../store';

import {
    EnterLocation,
    EnterParentIssue,
    EnterChildIssue,
    EnterSpecialCirc,
} from '../../utils/ga';

import Stepper from './stepper';
import QuestionZip from './question-zip';
import QuestionPrimaryTopic from './question-primary-topic';
import QuestionSecondaryTopic from './question-secondary-topic';
import QuestionSpecialCircumstances from './question-special-circumstances';

import CTAButton from '../../components/button-cta';
import QuickExitDialog from '../../components/quick-exit';

import Styles from './styles';

import IntakeDataContext from '../../wiring/intake-data-context';

const Intake = ({ classes }) => {
    const [step, setStep] = useState(1);
    const [validated, setValidated] = useState(false);
    const [intakeObj, setIntakeObj] = useState({
        locationId: -1,
        locationInput: '',
        issueTypeId: '',
        parentIssueTypeId: '',
        specialCircumstances: [],
    });
    const [primaryTopics, setPrimaryTopics] = useState([]);
    const [secondaryTopics, setSecondaryTopics] = useState([]);
    const [specialCircumstances, setSpecialCircumstances] = useState([]);

    const { updateUserInput } = useContext(IntakeDataContext);

    useEffect(() => {
        async function fetchPrimaryTopics() {
            const response = await axios(
                `${process.env.REACT_APP_API}/issue-types`,
            );
            setPrimaryTopics(response.data);
        }
        async function fetchSpecialCircumstances() {
            const response = await axios(
                `${process.env.REACT_APP_API}/special-circumstances`,
            );
            setSpecialCircumstances(response.data);
        }
        fetchSpecialCircumstances();
        fetchPrimaryTopics();
    }, []); // only get data on initial render

    useEffect(() => {
        const primaryTopicIndex = _.findIndex(primaryTopics, { id: intakeObj.parentIssueTypeId });
        if (primaryTopicIndex !== -1) {
            setSecondaryTopics(primaryTopics[primaryTopicIndex].children);
        }
    }, [intakeObj.parentIssueTypeId, primaryTopics]);

    useEffect(() => {
        // Update the document title using the current step
        document.title = `Colorado Resource Network: You are at step ${step} of 4`;
        history.push(`/intake?step=${step}`);

        // this brings focus to the content area every time the step changes
        if (questionContent.getElementsByTagName('button')[0]) {
            questionContent.getElementsByTagName('button')[0].focus();
        }
    }, [step]);

    const goHome = () => {
        setStep(1);
        history.push('/');
    };
    const advanceStep = (stepData) => {
        // Questions 2 & 3 trigger advancement at the same time they update values
        // so we need to pass those values through with advancement to capture them for GA
        if (step === 1) {
            EnterLocation(intakeObj.locationInput);
        } else if (step === 2) {
            EnterParentIssue(stepData);
        } else if (step === 3) {
            EnterChildIssue(stepData);
        } else if (step === 4) {
            EnterSpecialCirc(intakeObj.specialCircumstances);

            // make intakeObj accessible from other components
            // by adding it to a global context
            updateUserInput(intakeObj);
            history.push('/loading');
        }

        // step 4 unique process handled above
        if (step !== 4) {
            setStep(step + 1);
            setValidated(false);
            // by pushing the step into the url, we automatically hook into the 'scroll up' behavior of the router (that we added)
        }
    };

    let questionContent;
    return (
        <React.Fragment>
            <div className="container pt-3">
                <div className="row align-items-center">
                    <div className="col">
                        {step === 1 && (
                            <Button tabIndex="0" onClick={() => goHome()} className={classes.backBtn}>
                                <KeyboardBackspaceIcon />
                                &nbsp;Back to Homepage
                            </Button>
                        )}
                        {step > 1 && (
                            <Button tabIndex="0" onClick={() => setStep(step - 1)} className={classes.backBtn}>
                                <KeyboardBackspaceIcon />
                                &nbsp;Previous Step
                            </Button>
                        )}
                    </div>
                    <div className="col text-right">
                        <QuickExitDialog />
                    </div>
                </div>
            </div>
            <section className={classes.wrapper} ref={(input) => { questionContent = input; }}>
                <div className="container display-flex">
                    <div className="row justify-content-center text-center align-content-center">
                        <Stepper step={step} />
                    </div>
                    {step === 1 && <QuestionZip setValidated={setValidated} validated={validated} updateValue={setIntakeObj} defaultValue={intakeObj.locationInput} />}
                    {step === 2 && <QuestionPrimaryTopic advanceStep={advanceStep} data={primaryTopics} updateValue={setIntakeObj} defaultValue={intakeObj.parentIssueTypeId} />}
                    {step === 3 && <QuestionSecondaryTopic parentIssueTypeId={intakeObj.parentIssueTypeId} advanceStep={advanceStep} data={secondaryTopics} updateValue={setIntakeObj} defaultValue={intakeObj.issueTypeId} />}
                    {step === 4 && <QuestionSpecialCircumstances data={specialCircumstances} setValidated={setValidated} updateValue={setIntakeObj} defaultValue={intakeObj.specialCircumstances} />}
                    {(step === 1 || step === 4) && (
                        <div className="row">
                            <div className="col">
                                <CTAButton onClick={() => advanceStep()} disabled={!validated}>
                                    {step === 1 && 'Continue' }
                                    {step === 4 && 'Get Results' }
                                </CTAButton>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </React.Fragment>
    );
};

Intake.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(Intake);
