import Colors from '../../styles/colors';
import Breakpoints from '../../styles/breakpoints';
import TopSectionBackground from '../../images/homepage-mountains-2x.png';

const styles = theme => ({
    wrapper: {
        marginTop: 50,
        width: '100%',
        color: theme.palette.text.secondary,
        '@media (max-width: 740px)': {
            marginTop: 30,
        },
    },
    introTitle: {
        fontSize: 42,
        lineHeight: '52px',
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            fontSize: 28,
            lineHeight: '1.4em',
        },
    },
    topSection: {
        backgroundImage: `url(${TopSectionBackground})`,
        backgroundColor: Colors.backgroundBlue,
        backgroundSize: 'cover',
    },
    topSectionImage: {
        width: '100%',
    },
    topSectionContentWrapper: {
        minHeight: 400,
        padding: '50px 0 60px 0',
        [`@media (max-width: ${Breakpoints.md}px)`]: {
            minHeight: 'auto',
            padding: '30px 0',
        },
    },
    actionButton: {
        boxShadow: '0 20px 60px 0 rgba(17,34,51,0.4)',
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            width: '100%',
        },
    },
    middleSectionImage: {
        width: '60%',
    },
    middleSectionImageMobile: {
        width: '80%',
        maxWidth: 250,
        margin: '0 auto',
        padding: '15px 0',
    },
    partnerImage: {
        width: '100%',
        padding: '0 15px',
        [`@media (max-width: ${Breakpoints.md}px)`]: {
            padding: '15px 0',
        },
    },
    testimonialImage: {
        width: 100,
    },
    bottomSection: {
        backgroundColor: Colors.backgroundBlue,
    },
    bottomSectionContentWrapper: {
        padding: '60px 15%',
        [`@media (max-width: ${Breakpoints.md}px)`]: {
            padding: '30px 0',
        },
    },
    howItWorksLeftCol: {
        paddingLeft: 200,
        [`@media (max-width: ${Breakpoints.md}px)`]: {
            paddingLeft: 0,
        },
    },
    howItWorksBlock: {
        padding: '15px 0',
        '& :last-child': {
            paddingLeft: 0,
        },
    },
    howItWorksBlockSubTitle: {
        fontSize: theme.typography.body1.fontSize + 1,
        [`@media (max-width: ${Breakpoints.lg}px)`]: {
            fontSize: theme.typography.body1.fontSize + 1,
        },
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            fontSize: theme.typography.body1.fontSize + 1,
        },
    },
    checkIcon: {
        fill: Colors.green,
    },
    quoterBlock: {
        paddingTop: 15,
        alignItems: 'center',
    },
    quotation: {
        color: Colors.green,
        fontFamily: 'Lora',
        fontSize: 90,
        lineHeight: 0,
        margin: 0,
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            fontSize: 80,
            margin: '-20px 0',
        },
    },
    quoteBlockText: {
        fontFamily: 'Lora',
        fontSize: 18,
        color: Colors.darkElectricBlue,
        fontWeight: 'normal',
    },
    quoteImg: {
        width: 100,
        [`@media (max-width: ${Breakpoints.md}px)`]: {
            width: 70,
        },
    },
    hideOnDesktop: {
        display: 'none',
        [`@media (max-width: ${Breakpoints.md}px)`]: {
            display: 'block',
        },
    },
    hideOnMobile: {
        [`@media (max-width: ${Breakpoints.md}px)`]: {
            display: 'none',
        },
    },
});
export default styles;
