import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FontSizeContext from './font-size-context';

const FontSizeProvider = ({ children }) => {
    /**
    * Loading state/controls
    */
    // reference, https://medium.com/digio-australia/using-the-react-usecontext-hook-9f55461c4eae
    // intial values
    let setFontModifier = () => {};
    let fontModifier = {};

    const increaseFontSize = () => setFontModifier({ ...fontModifier, fontModifier: 5 });
    const decreaseFontSize = () => setFontModifier({ ...fontModifier, fontModifier: 0 });

    const fontSizeModifierState = {
        fontModifier: 0,
        increaseFontSize,
        decreaseFontSize,
    };

    [fontModifier, setFontModifier] = useState(fontSizeModifierState);

    return (
        <FontSizeContext.Provider value={fontModifier}>
            {children}
        </FontSizeContext.Provider>
    );
};

FontSizeProvider.propTypes = {
    children: PropTypes.object.isRequired,
};

export default FontSizeProvider;
