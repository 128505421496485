import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CTAButton from '../../components/button-cta';

import { history } from '../../store';
import Styles from './styles';
import ErrorIcon from './images/icon_help.png';

const MissingPage = ({ classes }) => {
    useEffect(() => {
        document.title = 'Colorado Resource Network: Error 404';
    }, []);

    const gotoHome = () => {
        history.push('/');
    };

    return (
        <React.Fragment>
            <section className={classes.errorHeader}>
                <div className="container d-flex" style={{ height: 58 }}>
                    <Button onClick={() => gotoHome()} className={classes.backBtn}>
                        <KeyboardBackspaceIcon />
                        &nbsp;Back to Home
                    </Button>
                </div>
            </section>
            <div className="container" style={{ paddingBottom: 200 }}>
                <div className="row">
                    <div className="col">
                        <section style={{ paddingBottom: 30 }}>
                            <Typography variant="h1">Page Missing</Typography>
                            <Typography variant="body1" style={{ paddingBottom: 30 }}>
                                The page you&lsquo;re trying to access is missing or not available at this time.
                            </Typography>
                            <CTAButton onClick={() => gotoHome()} parentClass={classes.cancelButton}>
                                Go Home
                            </CTAButton>
                        </section>
                    </div>
                    <div className="col d-none d-md-flex justify-content-center align-items-start">
                        <img alt="error icon" src={ErrorIcon} className={classes.errorIcon} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

MissingPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(MissingPage);
