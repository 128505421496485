import Colors from '../../styles/colors';
import Breakpoints from '../../styles/breakpoints';

const styles = theme => ({
    wrapper: {
        marginTop: 50,
        width: '100%',
        color: theme.palette.text.secondary,
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            marginTop: 30,
        },
    },
    backBtn: {
        color: Colors.royalBlue,
        fontSize: theme.typography.h4.fontSize,
        textTransform: 'capitalize',
        '& svg': {
            fill: Colors.iconBlue,
            marginRight: 10,
        },
    },
});
export default styles;
