import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

import QuestionIcon from './images/zip/icon_location.png';

import Styles from './styles-questions';

const QuestionLocationId = ({
    classes,
    validated,
    setValidated,
    updateValue,
    defaultValue,
}) => {
    const [locationInput, setLocationInput] = useState(defaultValue);
    const [validatedCity, setValidatedCity] = useState('');

    // invalid is handleded seperate from validationCity so we can manage the display of
    // errors independetly
    const [invalid, setInvalid] = useState(false);

    // code adapted from https://www.byteconf.com/blog/debouncing-with-react-hooks
    const useDebounce = (value, delay) => {
        // State and setters for debounced value
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            document.getElementById('inputField').focus();
        }, []);
        useEffect(() => {
            // Set debouncedValue to value (passed in) after the specified delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Return a cleanup function that will be called every time
            // useEffect is re-called. useEffect will only be re-called
            // if value changes (see the inputs array below).
            // This is how we prevent debouncedValue from changing if value is
            // changed within the delay period.
            // To put it in context, if the user is typing within our app's
            // search box, we don't want the debouncedValue to update until
            // they've stopped typing for more than 500ms.
            return () => {
                clearTimeout(handler);
            };
        },
        // Only re-call effect if value changes
        // You could also add the "delay" var to inputs array if you
        // need to be able to change that dynamically.
        [value]);
        return debouncedValue;
    };

    const debouncedLocation = useDebounce(locationInput, 500);
    const debouncedLocationValidationError = useDebounce(locationInput, 2000);

    useEffect(() => {
        async function validateLocation() {
            await axios(
                `${process.env.REACT_APP_API}/locations/${debouncedLocation}`,
            )
                .then((response) => {
                    const locationId = response.data.id;
                    updateValue(prevState => ({ ...prevState, locationId }));
                    setValidatedCity(response.data.city);
                    setValidated(true);
                    setInvalid(false);
                })
                .catch(() => {
                    setValidated(false);
                });
        }

        if (debouncedLocation !== '') {
            validateLocation();
        } else {
            // if the input is empty, it's not valid
            setValidated(false);
        }
        updateValue(prevState => ({ ...prevState, locationInput: debouncedLocation }));
    }, [debouncedLocation, updateValue, setValidated]);

    useEffect(() => {
        async function validateLocation() {
            await axios(
                `${process.env.REACT_APP_API}/locations/${debouncedLocation}`,
            )
                .then(() => {
                    // this is handled elsewhere, we are only trying to catch errors
                })
                .catch(() => {
                    setInvalid(true);
                });
        }

        if (debouncedLocation !== '') {
            validateLocation();
        } else {
            // if the input is empty, it's not valid, but it's also not an error
            setInvalid(false);
        }
    }, [debouncedLocationValidationError, setInvalid]);

    return (
        <React.Fragment>
            <Typography variant="h1" style={{ paddingBottom: 30 }}>
                Where do you live?
            </Typography>
            <div className="row">
                <div className="col-12 col-md-7">
                    <section style={{ paddingBottom: 30 }}>
                        <Typography variant="body2" style={{ paddingBottom: 30 }}>
                            We will search in this community to find local resources and services.
                        </Typography>
                        <InputBase
                            placeholder="Enter your zip code or city"
                            value={locationInput}
                            onChange={event => setLocationInput(event.target.value)}
                            style={{ marginBottom: 15 }}
                            id="inputField"
                            classes={{
                                root: classes.textInputRoot,
                                input: classes.textInputBackground,
                                focused: classes.textInputBackgroundFocused,
                            }}
                            inputProps={{
                                'aria-label': 'Enter Zip Code or City Name',
                            }}
                        />
                        <span role="status">
                            {invalid && (
                                <span className={classes.validationIconWrapper} style={{ marginTop: -1 }}>
                                    <ErrorIcon className={classes.invalidatedIcon} />
                                    <Typography variant="body1" style={{ display: 'inline-flex', width: 200 }}>
                                        Sorry, we do not recognize that location
                                    </Typography>
                                </span>
                            )}
                            {validated && (
                                <span className={classes.validationIconWrapper}>
                                    <DoneIcon className={classes.validatedIcon} />
                                    <Typography variant="body1" style={{ display: 'inline-flex', width: 200 }}>
                                        {validatedCity}
                                    </Typography>
                                </span>
                            )}
                        </span>
                    </section>
                </div>
                <div className="col d-none d-md-flex justify-content-center align-items-start">
                    <img alt="question icon" src={QuestionIcon} className={classes.questionIcon} />
                </div>
            </div>
        </React.Fragment>
    );
};

QuestionLocationId.propTypes = {
    classes: PropTypes.object.isRequired,
    setValidated: PropTypes.func.isRequired,
    defaultValue: PropTypes.string.isRequired,
    updateValue: PropTypes.func.isRequired,
    validated: PropTypes.bool.isRequired,
};

export default withStyles(Styles)(QuestionLocationId);
