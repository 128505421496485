import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import ClearIcon from '@material-ui/icons/Clear';

import Styles from './styles';

const QuickExitDialog = ({ classes, onClose, open }) => (
    <Dialog
        onClose={() => onClose()}
        open={open}
        classes={{
            paper: classes.modalPaper,
            paperFullScreen: classes.modalRoot,
        }}
        BackdropProps={{
            classes: { root: classes.modalBackdropRoot },
        }}
    >
        <div className="row text-right">
            <div className="col">
                <button aira-label="close dialog" className={classes.modalCloseButton} type="button" onClick={() => onClose()}>
                    <ClearIcon />
                </button>
            </div>
        </div>
        <div className="row text-center">
            <div className="col">
                <Typography variant="h3" className={classes.modalTitle}>Quick Exit</Typography>
                <Typography variant="body1" className={classes.modalContent}>
                    To leave this website quickly, click the &quot;Quick Exit&quot; button.
                    If this is an emergency or you need immediate help, please call 911.
                    <br />
                    <br />
                    If you are a victim of family violence, contact the National Domestic Violence Hotline: 800.799.7233 or TTY 800.787.3224.
                </Typography>
            </div>
        </div>
    </Dialog>
);

QuickExitDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(Styles)(QuickExitDialog);
