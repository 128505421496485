import Breakpoints from '../../styles/breakpoints';
import Colors from '../../styles/colors';

const styles = ({
    wrapper: {
        color: Colors.royalBlue,
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.yellow,
        height: 56,
        minWidth: 240,
        borderRadius: 30,
        padding: '0 15px',
        margin: '15px 0',
        '&:hover': {
            backgroundColor: Colors.yellowHover,
        },
        '&:active': {
            backgroundColor: Colors.yellowActive,
        },
        '&:disabled': {
            color: 'white',
            backgroundColor: '#00246D',
            opacity: 0.2,
        },
        [`@media (max-width: ${Breakpoints.sm}px)`]: {},
    },
});

export default styles;
