import Colors from '../../styles/colors';
import Breakpoints from '../../styles/breakpoints';

const styles = theme => ({
    textInputRoot: {
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        color: Colors.lightBlue,
        backgroundColor: 'white',
        outline: 0,
        width: 325,
        padding: 8,
        borderRadius: 5,
        border: `1px solid ${Colors.borderGrey}`,
    },
    textInputBackground: {
        borderRadius: 5,
        padding: 10,
        // outline: `${Colors.iconBlue} 1px solid`,
    },
    textInputBackgroundFocused: {
        borderRadius: 5,
        border: `2px solid ${Colors.iconBlue}`,
        margin: '-1px 1px 1px -1px',
    },
    '@keyframes borderAnimation': {
        from: {
            border: '3px solid rgba(0, 36, 109, 1)',
        },
        to: {
            border: '3px solid rgba(0, 36, 109, .2)',
        },
    },
    selectionButton: {
        padding: 20,
        backgroundColor: 'white',
        boxShadow: '0 4px 6px 0 rgba(51,71,91,0.1)',
        borderRadius: 10,
        marginBottom: 15,
        [`@media (min-width: ${Breakpoints.sm}px)`]: {
            '&:focus': {
                animationName: 'borderAnimation',
                animationIterationCount: 'infinite',
                animationTimingFunction: 'ease',
                animationDirection: 'alternate',
                animationDuration: '1s',
                marginLeft: -3,
                marginTop: -3,
                marginBottom: 12,
            },
        },
    },
    selectionButtonSelected: {
        border: `3px solid ${Colors.iconBlue}`,
        marginLeft: -3,
        marginTop: -3,
        marginBottom: 12,
        '&:focus': {
            animationName: 'none',
        },
    },
    buttonIcon: {
        marginRight: 15,
        fill: Colors.glitter,
        height: 40,
        width: 'auto',
    },
    modalTitle: {
        padding: '15px 30px',
    },
    modalContent: {
        padding: '15px 30px 60px',
    },
    modalCloseButton: {
        marginTop: 15,
        marginRight: 15,
        cursor: 'pointer',
        '&:focus': {
            border: `1px solid ${Colors.yellow}`,
            marginLeft: -1,
        },
        '& svg': {
            fill: Colors.royalBlue,
        },
    },
    modalBackdropRoot: {
        backgroundColor: 'rgba(0, 37, 94, 0.4)',
    },
    modalPaper: {
        borderRadius: 20,
    },
    validatedIcon: {
        fill: Colors.iconBlue,
        marginLeft: 15,
        marginRight: 5,
    },
    invalidatedIcon: {
        fill: Colors.royalBlue,
        marginLeft: 15,
        marginRight: 5,
    },
    validationIconWrapper: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    questionIcon: {
        width: '100%',
        maxWidth: 300,
    },
    buttonCheckBox: {
        width: 20,
        height: 20,
        border: `2px solid ${Colors.borderGrey}`,
        borderRadius: 5,
        display: 'inline-flex',
    },
    buttonCheckBoxSelected: {
        width: 20,
        height: 20,
        display: 'inline-flex',
        borderRadius: 5,
        backgroundColor: Colors.iconBlue,
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            fill: 'white',
            height: 15,
        },
    },
    dialogLink: {
        '&:focus': {
            border: `1px solid ${Colors.yellow}`,
            marginLeft: -1,
        },
    },
    checkboxText: {
        color: Colors.charcoal,
        fontWeight: 'normal',
        display: 'inline',
        paddingLeft: 15,
    },
    textLink: {
        textDecoration: 'underline',
        fontWeight: '700',
        color: Colors.royalBlue,
    },
});
export default styles;
