import React from 'react';
import { Switch, Route } from 'react-router-dom';
import EmptyLayout from '../layouts/empty';
import NormalLayout from '../layouts/normal';

import withRoot from '../wiring/withRoot';

const App = () => (
    <Route
        render={({ location }) => (
            <Switch location={location}>
                <Route exact path="/loading" component={EmptyLayout} />
                <Route path="/" component={NormalLayout} />
            </Switch>
        )}
    />
);

export default withRoot(App);
