import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Home from '../../routes/home';
import Intake from '../../routes/intake';
import Results from '../../routes/results';
import NoResults from '../../routes/results/no-results';
import Recommendation from '../../routes/recommendation/index-standalone';
import ContactPartner from '../../routes/recommendation/contact-partner';
import MissingPage from '../../routes/error-pages/missing';
import withRoot from '../../wiring/withRoot';

import FontSizeContext from '../../wiring/font-size-context';
import themer from '../../styles/materialTheme';

const NormalLayout = () => {
    const { fontModifier } = useContext(FontSizeContext);

    return (
        <Route
            render={({ location }) => (
                <MuiThemeProvider theme={themer(fontModifier)}>
                    <Header />
                    <main>
                        <Switch location={location}>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/intake" component={Intake} />
                            <Route exact path="/results" component={Results} />
                            <Route exact path="/no-results" component={NoResults} />
                            <Route exact path="/recommendation" component={Recommendation} />
                            <Route exact path="/contact-partner" component={ContactPartner} />
                            <Route path="404" component={MissingPage} />
                            <Route path="/" component={MissingPage} />
                        </Switch>
                    </main>
                    <Footer />
                </MuiThemeProvider>
            )}
        />
    );
};

export default withRoot(NormalLayout);
