export default {
    lightBlue: '#324B72',
    iconBlue: '#70A5EE',
    backgroundBlue: '#EBF0FA',
    red: '#FF4958',
    errorRed: '#B93A43',
    yellow: '#FFD900',
    yellowHover: '#FFE555',
    yellowActive: '#F1CE03',
    green: '#54B187',
    borderGrey: '#D1D4E0',
    royalBlue: '#00246D',
    glitter: '#E1EAF8',
    darkElectricBlue: '#606D82',
    charcoal: '#33475B',
};
