import Colors from '../../styles/colors';

const styles = () => ({
    modalTitle: {
        padding: '15px 30px',
    },
    modalContent: {
        padding: '15px 30px 60px',
    },
    modalCloseButton: {
        marginTop: 15,
        marginRight: 15,
        cursor: 'pointer',
        '&:focus': {
            border: `1px solid ${Colors.yellow}`,
            marginLeft: -1,
        },
        '& svg': {
            fill: Colors.royalBlue,
        },
    },
    modalBackdropRoot: {
        backgroundColor: 'rgba(0, 37, 94, 0.4)',
    },
    modalPaper: {
        borderRadius: 20,
    },
    dialogLink: {
        '&:focus': {
            border: `1px solid ${Colors.yellow}`,
            marginLeft: -1,
        },
        display: 'inline-block',
    },
    textLink: {
        textDecoration: 'underline',
        fontWeight: '700',
        color: Colors.royalBlue,
    },
    helpBtn: {
        '&:focus': {
            border: `1px solid ${Colors.yellow}`,
            marginLeft: -1,
        },
    },
    helpIcon: {
        fill: Colors.royalBlue,
        marginLeft: 10,
        marginBottom: 3,
    },
});
export default styles;
