import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';

import Dialog from '@material-ui/core/Dialog';

import Styles from './styles-questions';

import BenefitsIcon from './images/primary/benefits.png';
import EstatesIcon from './images/primary/estates_wills.png';
import HealthIcon from './images/primary/health.png';
import HousingIcon from './images/primary/housing.png';
import MoneyIcon from './images/primary/money.png';
import FamilyIcon from './images/primary/families.png';

import QuestionIcon from './images/primary/icon_help.png';

const QuestionPrimaryTopic = ({
    classes,
    updateValue,
    defaultValue,
    data,
    advanceStep,
}) => {
    const [primaryTopic, setPrimaryTopic] = useState(defaultValue);
    const [dialogOpen, setDialogOpen] = useState(false);

    const buttonSelect = (topic) => {
        updateValue(prevState => ({ ...prevState, parentIssueTypeId: topic.id }));
        setPrimaryTopic(topic.id);
        advanceStep(topic.id);
    };

    const IconMap = {
        'BE-00-00-00-00': BenefitsIcon,
        'MO-00-00-00-00': MoneyIcon,
        'HO-00-00-00-00': HousingIcon,
        'ES-00-00-00-00': EstatesIcon,
        'HE-00-00-00-00': HealthIcon,
        'FA-00-00-00-00': FamilyIcon,
    };

    return (
        <React.Fragment>
            <Typography variant="h1" style={{ paddingBottom: 30 }}>
                What would you like help with?
            </Typography>
            <Typography variant="body2" style={{ paddingBottom: 30 }}>
                Choose a general topic to find resources and referrals in your area.
            </Typography>
            <div className="row">
                <div className="col-12 col-md-7">
                    <section style={{ paddingBottom: 30 }}>
                        {data.map(topic => (
                            <button key={topic.friendlyTitle} type="button" onClick={() => buttonSelect(topic)} className={`container text-left ${classes.selectionButton} ${primaryTopic === topic.id && classes.selectionButtonSelected}`}>
                                <div className="row no-gutters">
                                    <div className="col-auto" style={{ width: 80 }}>
                                        <img alt="" src={IconMap[topic.id]} className={classes.buttonIcon} />
                                    </div>
                                    <div className="col">
                                        <Typography variant="h4">{topic.friendlyTitle}</Typography>
                                        <Typography variant="body1" style={{ paddingTop: 5, paddingBottom: 0 }}>{topic.summary}</Typography>
                                    </div>
                                </div>
                            </button>
                        ))}
                    </section>
                    <section style={{ paddingBottom: 30 }}>
                        <button className={classes.dialogLink} type="button" onClick={() => setDialogOpen(true)}>
                            <Typography variant="body1" className={classes.textLink}>Looking for something else?</Typography>
                        </button>
                        <SimpleDialog classes={classes} open={dialogOpen} onClose={() => setDialogOpen(false)} />
                    </section>
                </div>
                <div className="col d-none d-md-flex justify-content-center align-items-start">
                    <img alt="question icon" src={QuestionIcon} className={classes.questionIcon} />
                </div>
            </div>
        </React.Fragment>
    );
};

QuestionPrimaryTopic.propTypes = {
    classes: PropTypes.object.isRequired,
    defaultValue: PropTypes.string.isRequired,
    updateValue: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    advanceStep: PropTypes.func.isRequired,
};

export default withStyles(Styles)(QuestionPrimaryTopic);


function SimpleDialog(props) {
    const { onClose, classes, ...other } = props;

    return (
        <Dialog
            onClose={() => onClose()}
            {...other}
            classes={{
                paper: classes.modalPaper,
                paperFullScreen: classes.modalRoot,
            }}
            BackdropProps={{
                classes: { root: classes.modalBackdropRoot },
            }}
        >
            <div className="row text-right">
                <div className="col">
                    <button aria-label="close dialog" className={classes.modalCloseButton} type="button" onClick={() => onClose()}>
                        <ClearIcon />
                    </button>
                </div>
            </div>
            <div className="row text-center">
                <div className="col">
                    <Typography variant="h3" className={classes.modalTitle}>Looking for something else?</Typography>
                    <Typography variant="body1" className={classes.modalContent}>
                        <a href="https://www.courts.state.co.us/userfiles/file/Self_Help/Sherlocks/Contact%20list%20for%20public(20).pdf" target="_blank" rel="noopener noreferrer" className={classes.textLink}>Contact your county&lsquo;s self-help center</a>
                        &nbsp;to ask about a topic that is not listed here.
                    </Typography>
                </div>
            </div>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
