import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GetParameterByName from '../../utils/getParam';

import { history } from '../../store';
import Styles from './styles';
import IntakeDataContext from '../../wiring/intake-data-context';

const Recommendation = ({ classes, location }) => {
    const { resultsObj } = useContext(IntakeDataContext);
    const recId = GetParameterByName('id', location.search);
    const [recommendation, setRecommendation] = useState({});

    useEffect(() => {
        // check if we had results data we can use to build from
        // if we don't have results data, push the user to the homepage
        if (Object.keys(resultsObj).length === 0) {
            history.push('/');
        } else {
            const selectedRecommendation = resultsObj.find(
                (rec) => rec.id.toString() === recId,
            );
            setRecommendation(selectedRecommendation);
        }
    }, [resultsObj, recId]); // on initial render, check if we have results ready (we have to)

    return (
        <React.Fragment>
            <section className={classes.wrapper}>
                {recommendation.name}
            </section>
        </React.Fragment>
    );
};

Recommendation.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default withStyles(Styles)(Recommendation);
