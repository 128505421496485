import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IntakeDataContext from './intake-data-context';

const IntakeDataProvider = ({ children }) => {
    /*
    * Loading state/controls
    * reference, https://medium.com/digio-australia/using-the-react-usecontext-hook-9f55461c4eae
    */

    // let setIntakeData;
    // let intakeData;
    // const updateUserInput = userInput => setIntakeData({ ...intakeData, inputObj: userInput });
    // const updateReturnedResults = resultData => setIntakeData({ ...intakeData, resultsObj: resultData });

    const updateUserInput = (userInput) => {
        setIntakeData(prevState => {
            return { ...prevState, inputObj: userInput };
        });
    };

    const updateReturnedResults = (resultData) => {
        setIntakeData(prevState => {
            return { ...prevState, resultsObj: resultData };
        });
    };


    const intakeDataState = {
        resultsObj: [],
        inputObj: [],
        updateUserInput,
        updateReturnedResults,
    };

    const [intakeData, setIntakeData] = useState(intakeDataState);

    return (
        <IntakeDataContext.Provider value={intakeData}>
            {children}
        </IntakeDataContext.Provider>
    );
};

IntakeDataProvider.propTypes = {
    children: PropTypes.object.isRequired,
};

export default IntakeDataProvider;
