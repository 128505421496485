import Colors from '../../styles/colors';

const styles = () => ({
    wrapper: {
        minHeight: 500,
        marginTop: 50,
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '@media (max-width: 740px)': {
            marginTop: 30,
        },
    },
    iconCircle: {
        height: 195,
        width: 195,
        backgroundColor: 'white',
        borderRadius: '100%',
        border: `15px solid ${Colors.glitter}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
    },
    icon: {
        width: 80,
    },
});
export default styles;
