import React from 'react';
import 'bootstrap-css-only/css/bootstrap-grid.min.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor, history } from './store';
import App from './routes';

import FontSizeProvider from './wiring/font-size-provider';
import IntakeDataProvider from './wiring/intake-data-provider';

import ScrollToTop from './utils/scroll-to-top';
import GAUtils from './utils/ga';

import 'sanitize.css/sanitize.css';
import './index.css';

GAUtils.initGA();

history.listen(() => {
    ScrollToTop();
});

ReactDOM.render(
    <Provider store={store}>
        <PersistGate
            loading={null}
            persistor={persistor}
        >
            <ConnectedRouter history={history}>
                <FontSizeProvider>
                    <IntakeDataProvider>
                        <App />
                    </IntakeDataProvider>
                </FontSizeProvider>
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);
