import Colors from '../../styles/colors';
import Breakpoints from '../../styles/breakpoints';

const styles = theme => ({
    wrapper: {
        color: theme.palette.text.secondary,
    },
    contactIcons: {
        color: Colors.green,
        width: 24,
    },
    textLink: {
        color: Colors.royalBlue,
        fontWeight: 'bold',
    },
    headerTextBlock: {
        backgroundColor: Colors.glitter,
        paddingBottom: 30,
        marginBottom: 30,
    },
    contactTextBlock: {
        paddingBottom: 15,
        paddingLeft: 50,
        paddingRight: 50,
        [`@media (max-width: ${Breakpoints.sm}px)`]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    actionButton: {
        width: '100%',
        boxShadow: '0 20px 60px 0 rgba(17,34,51,0.4)',
    },
    breakReturn: {
        display: 'block',
        height: 1,
        border: 0,
        borderTop: `${Colors.borderGrey} 1px solid`,
        padding: 0,
        margin: '15px 50px 30px 50px',
    },
    launchIcon: {
        color: 'grey',
        width: 20,
        marginLeft: 5,
    },
    // floating label TextField
    cssLabel: {
        color: Colors.charcoal,
        '&$cssFocused': {
            color: Colors.textBlue,
        },
    },
    cssLabelLg: {
        fontSize: 20,
        color: Colors.charcoal,
        '&$cssFocused': {
            color: Colors.textBlue,
        },
    },
    disabled: {},
    focused: {},
    error: {},
    cssOutlinedInput: {
        backgroundColor: 'white',
        '&$cssFocused $notchedOutline': {
            borderColor: Colors.iconBlue,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: Colors.iconBlue,
        },
    },
    cssOutlinedInputLg: {
        fontSize: 26,
        backgroundColor: 'white',
        '&$cssFocused $notchedOutline': {
            borderColor: Colors.iconBlue,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: Colors.iconBlue,
        },
    },
    notchedOutlineError: {
        borderColor: Colors.errorRed,
    },
    cssFocused: {},
    notchedOutline: {},
    // end floating label TextField
    formRow: {
        padding: '15px 0',
    },
    recommendationHeader: {
        marginBottom: 40,
        backgroundColor: 'white',
    },
    backBtn: {
        color: Colors.royalBlue,
        fontSize: theme.typography.h4.fontSize,
        textTransform: 'capitalize',
        '& svg': {
            fill: Colors.iconBlue,
            marginRight: 10,
        },
    },
    selectionButton: {
        display: 'inline-flex',
        margin: 0,
        '&:focus': {
            border: `2px solid ${Colors.yellow}`,
            margin: -2,
        },
    },
    buttonCheckBox: {
        width: 20,
        height: 20,
        border: `2px solid ${Colors.borderGrey}`,
        borderRadius: 5,
        display: 'inline-flex',
    },
    buttonCheckBoxSelected: {
        width: 20,
        height: 20,
        display: 'inline-flex',
        borderRadius: 5,
        backgroundColor: Colors.iconBlue,
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            fill: 'white',
            height: 15,
        },
    },
    cancelButton: {
        backgroundColor: Colors.backgroundBlue,
        border: `2px solid ${Colors.borderGrey}`,
        '&:hover': {
            backgroundColor: Colors.lightBlue,
            color: 'white',
            border: 0,
        },
    },
    errorMsg: {
        color: Colors.errorRed,
        fontWeight: 700,
    },
    emailIcon: {
        height: 250,
    },
    screenReaderOnly: {
        position: 'absolute',
        left: -10000,
        width: 1,
        height: 1,
        overflow: 'hidden',
    },
});
export default styles;
