import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Styles from './styles';
import HeaderLogo from './images/colorado-resource-network-logo-2x.png';

import FontSizeContext from '../../wiring/font-size-context';
import { history } from '../../store';

const Header = ({ classes }) => {
    const { fontModifier, increaseFontSize, decreaseFontSize } = useContext(FontSizeContext);

    const goHome = () => {
        history.push('/');
    };

    return (
        <>
            <div className={classes.covidBanner}>
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <Typography variant="body1" className={classes.covidBannerText}>
                                ALERT: We have resources to inform and help those affected by COVID-19 (Coronavirus).&nbsp;
                                <Link to="/loading?special=8" className={classes.covidBannerLink}>Learn more here</Link>
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            <header className={classes.wrapper}>
                <div className="container">
                    <div className="row" style={{ alignItems: 'center' }}>
                        <div className="col-auto">
                            <button aria-label="Go Home" type="button" className={classes.logo} onClick={() => goHome()}>
                                <img src={HeaderLogo} alt="Colorado Resource Network" style={{ height: 60 }} />
                            </button>
                        </div>
                        <div className="d-none d-md-block col text-right">
                            <Typography style={{ display: 'inline-flex', color: 'white', fontSize: 16 }}>Text size:&nbsp;</Typography>
                            <button aria-label="Decrease Font Size" type="button" onClick={() => decreaseFontSize()} className={fontModifier === 0 ? `${classes.activeText} ${classes.smallText}` : classes.smallText}>A</button>
                            &nbsp;
                            <button aria-label="Increase Font Size" type="button" onClick={() => increaseFontSize()} className={fontModifier > 0 ? `${classes.activeText} ${classes.largeText}` : classes.largeText}>A</button>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(Header);
