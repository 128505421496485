import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Styles from './styles-stepper';


const Intake = ({ classes, step }) => (
    <section className={classes.wrapper}>
        <Typography variant="body1">
            Step
            {' '}
            {step}
            {' '}
            of 4
        </Typography>
        <div className={classes.barWrapper}>
            <div className={classes.bar} style={{ width: `${step * 25}%` }} />
        </div>
    </section>
);

Intake.propTypes = {
    classes: PropTypes.object.isRequired,
    step: PropTypes.number.isRequired,
};

export default withStyles(Styles)(Intake);
